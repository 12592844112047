import classNames from 'classnames';
import Markdown from 'markdown-to-jsx';
import { FormEventHandler, useEffect, useMemo, useState } from 'react';
import globalStyles from '../../Global.module.scss';
import { useAppDispatch } from '../../hooks';
import useTranslation from '../../locales/useTranslation';
import { startChat } from '../../reducers/startScreenReducer';
import styles from './Start.module.scss';
import Checkbox from './checkbox';
import Input from './input';
import Radio from './radio';
import Select from './select';

const INTRO_KEY = 'start-screen.intro';
const START_CHAT_KEY = 'start-screen.start-chat';
const FORM_KEY = 'start-screen.form';
const FORM_FIELDS_KEY = `${FORM_KEY}.fields`;

type FormFieldType =
  | 'text'
  | 'email'
  | 'tel'
  | 'number'
  | 'date'
  | 'time'
  | 'datetime-local'
  | 'week'
  | 'month'
  | 'password'
  | 'radio'
  | 'checkbox'
  | 'select';

type FormField = {
  type: FormFieldType;
  name: string;
  label: string;
  placeholder?: string;
  value: string | undefined;
  defaultValue: string | undefined;
  options: Record<string, string> | undefined;
  required: boolean;
  multiple: boolean;
  min: string | undefined;
  max: string | undefined;
  pattern: string | undefined;
};

const StartScreen = () => {
  const translate = useTranslation();
  const dispatch = useAppDispatch();
  const [fields, setFields] = useState<Record<string, FormField>>({});

  const onStartChat = () => {
    const formValues = Object.entries(fields).reduce<
      Record<string, string | undefined>
    >((acc, [key, field]) => ({ ...acc, [key]: field.value }), {});
    dispatch(startChat(formValues));
  };

  const intro = translate(INTRO_KEY);
  const buttonLabel = translate(START_CHAT_KEY);

  const formFields = useMemo(() => {
    return (
      translate(FORM_FIELDS_KEY)
        ?.split(';')
        .map((n) => n.trim()) ?? []
    );
  }, [translate(FORM_FIELDS_KEY)]);

  useEffect(() => {
    setFields(
      formFields.reduce<Record<string, FormField>>(
        (acc, name) => ({
          ...acc,
          [name]: toFormField(name, translate),
        }),
        {}
      )
    );
  }, [translate, formFields]);

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    onStartChat();
  };

  const handleOnFieldValueChange = (name: string) => (value: string) => {
    setFields((fields) => ({
      ...fields,
      [name]: { ...fields[name], value },
    }));
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={classNames(styles.container, 'chat__startScreen')}
    >
      <div className={classNames(styles.body, 'chat__startScreen__body')}>
        {intro && (
          <div className={classNames(styles.intro, 'chat__startScreen__intro')}>
            <Markdown
              options={{
                overrides: {
                  a: Link,
                },
              }}
            >
              {intro}
            </Markdown>
          </div>
        )}

        <div
          className={classNames(styles.formFields, 'chat__startScreen__fields')}
        >
          {Object.values(fields).map(
            ({
              type,
              name,
              label,
              placeholder,
              defaultValue,
              options,
              required,
              multiple,
              min,
              max,
              pattern,
            }) => (
              <div
                key={name}
                className={classNames(
                  styles.formField,
                  'chat__startScreen__field'
                )}
              >
                {(() => {
                  switch (type) {
                    case 'radio':
                      return (
                        <>
                          <div>{label}</div>
                          {Object.entries(options || {}).map(([key, value]) => (
                            <Radio
                              name={name}
                              value={key}
                              label={value}
                              defaultChecked={key === defaultValue}
                              required={required}
                              onChange={handleOnFieldValueChange(name)}
                            />
                          ))}
                        </>
                      );
                    case 'checkbox':
                      return (
                        <Checkbox
                          name={name}
                          label={label}
                          defaultValue={defaultValue}
                          required={required}
                          onChange={handleOnFieldValueChange(name)}
                        />
                      );
                    case 'select':
                      return (
                        <Select
                          name={name}
                          label={label}
                          defaultValue={defaultValue}
                          required={required}
                          multiple={multiple}
                          options={options}
                          onChange={handleOnFieldValueChange(name)}
                        />
                      );
                    default:
                      return (
                        <Input
                          type={type}
                          name={name}
                          label={label}
                          placeholder={placeholder}
                          defaultValue={defaultValue}
                          required={required}
                          multiple={multiple}
                          min={min}
                          max={max}
                          pattern={pattern}
                          onChange={handleOnFieldValueChange(name)}
                        />
                      );
                  }
                })()}
              </div>
            )
          )}
        </div>
      </div>
      <div className={classNames(styles.footer, 'chat__startScreen__footer')}>
        <button
          type="submit"
          className={classNames(
            globalStyles.button,
            globalStyles.buttonPrimary,
            styles.startChatButton
          )}
        >
          {buttonLabel}
        </button>
      </div>
    </form>
  );
};

const Link: React.FC<{
  title: string;
  href: string;
  children: React.ReactNode;
}> = ({ title, href, children }) => {
  return (
    <a href={href} title={title} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

const toFormField = (
  name: string,
  translate: (id: string) => string | undefined
): FormField => {
  const type =
    (translate(`${FORM_KEY}.${name}.type`) as FormFieldType) || 'text';
  const required = translate(`${FORM_KEY}.${name}.required`) === 'true';
  const multiple = translate(`${FORM_KEY}.${name}.multiple`) === 'true';
  const placeholder = translate(`${FORM_KEY}.${name}.placeholder`);
  const label = translate(`${FORM_KEY}.${name}.label`) || '';
  const defaultValue = translate(`${FORM_KEY}.${name}.defaultValue`);
  const min = translate(`${FORM_KEY}.${name}.min`);
  const max = translate(`${FORM_KEY}.${name}.max`);
  const pattern = translate(`${FORM_KEY}.${name}.pattern`);
  const options = toFormFieldOptions(translate(`${FORM_KEY}.${name}.options`));
  return {
    name,
    label,
    placeholder,
    value: getTypeSpecificValue(type, defaultValue, options) || '',
    defaultValue,
    options,
    type,
    required,
    multiple,
    min,
    max,
    pattern,
  };
};

const getTypeSpecificValue = (
  type: FormFieldType,
  defaultValue: string | undefined,
  options: Record<string, string>
) => {
  if (defaultValue) {
    return defaultValue;
  } else if (type === 'select') {
    return Object.keys(options)[0] || '';
  } else {
    return '';
  }
};

const toFormFieldOptions = (options = ''): Record<string, string> => {
  return options
    .split(';')
    .map((option) => option.trim())
    .reduce<Record<string, string>>((acc, option) => {
      const [key, value] = option.split(':').map((v) => v.trim());
      return { ...acc, [key]: value };
    }, {});
};

export default StartScreen;
