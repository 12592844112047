import { RootState } from '.';

const LOCAL_STORAGE_LAST_SAVED_ITEM_NAME = 'bf-webclient-state-last-saved-at';
const LOCAL_STORAGE_STATE_ITEM_NAME = 'bf-webclient-state';

export const loadState = (clientName: string) => {
  try {
    if (!supportsLocalStorage()) {
      return undefined;
    }

    // get last save time
    const lastSavedAt = getStoreItem(
      clientName,
      LOCAL_STORAGE_LAST_SAVED_ITEM_NAME
    );
    if (isExpired(lastSavedAt)) {
      return undefined;
    } else {
      // load persisted state
      const serializedState = getStoreItem(
        clientName,
        LOCAL_STORAGE_STATE_ITEM_NAME
      );
      if (serializedState === null) {
        return undefined;
      }
      return JSON.parse(serializedState);
    }
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const saveState = (clientName: string, state: Partial<RootState>) => {
  try {
    // save state
    const serializedState = JSON.stringify(state);
    setStoreItem(clientName, LOCAL_STORAGE_STATE_ITEM_NAME, serializedState);

    // save current date
    const now = new Date().toISOString();
    setStoreItem(clientName, LOCAL_STORAGE_LAST_SAVED_ITEM_NAME, now);
  } catch (err) {
    // ignorieren
    console.error(err);
  }
};

const isExpired = (lastSavedAt: string | null): boolean => {
  if (lastSavedAt === null) {
    return false;
  }
  const expiresAt = new Date(lastSavedAt);
  expiresAt.setHours(expiresAt.getHours() + 12);

  const now = new Date();
  return now > expiresAt;
};

const supportsLocalStorage = () => typeof localStorage !== 'undefined';
const getStoreItem = (clientName: string, storeItemName: string) =>
  localStorage.getItem(`${clientName}_${storeItemName}`);
const setStoreItem = (
  clientName: string,
  storeItemName: string,
  value: string
) => localStorage.setItem(`${clientName}_${storeItemName}`, value);
