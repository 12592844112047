import {
  ActionTypes,
  Button,
  ButtonTypeEnum,
  Message,
  PostbackButton,
  UrlButton,
  requestNLUEvent as coreRequestNLUEvent,
} from '@botfabrik/engine-domain';
import type { EnableConversationRatingType } from '../types';
import handleQuickReplyClick from './handleQuickReplyClick';
import sendMessageFromUser from './sendMessageFromUser';

export const MESSAGE_TO_PARENT_PAGE = 'MESSAGE_TO_PARENT_PAGE';
export const RESTORE_PREVIOUS_CHAT_MESSAGES = 'RESTORE_PREVIOUS_CHAT_MESSAGES';
export const AUDIO_MESSAGE_FROM_USER = 'AUDIO_MESSAGE_FROM_USER';
export const ENABLE_SPEECH_SUPPORT = 'ENABLE_SPEECH_SUPPORT';
export const ENABLE_TRANSCRIPT_EXPORT = 'ENABLE_TRANSCRIPT_EXPORT';
export const EXPORT_TRANSCRIPT = 'EXPORT_TRANSCRIPT';
export const RECEIVE_SPEECH_TRANSCRIPTION = 'RECEIVE_SPEECH_TRANSCRIPTION';
export const SHOW_GENERAL_CONDITIONS = 'SHOW_GENERAL_CONDITIONS';
export const ENABLE_CONVERSATION_RATING = 'ENABLE_CONVERSATION_RATING';

export const GET_STARTED = 'webclient.get.started';
export const EXPAND_WINDOW = 'webclient.expand.window';
export const DOWNLOAD_TRANSCRIPT_PDF = 'webclient.transcript.pdf.download';

/**
 * Diese Actions sollen nicht ans Backend übermittelt werden (siehe socketMiddleware.ts)
 */
export const INTERNAL_ACTIONS = [
  MESSAGE_TO_PARENT_PAGE,
  RESTORE_PREVIOUS_CHAT_MESSAGES,
  AUDIO_MESSAGE_FROM_USER,
  ENABLE_SPEECH_SUPPORT,
  ENABLE_TRANSCRIPT_EXPORT,
  EXPORT_TRANSCRIPT,
  RECEIVE_SPEECH_TRANSCRIPTION,
  SHOW_GENERAL_CONDITIONS,
  ENABLE_CONVERSATION_RATING,
];

export { handleQuickReplyClick, sendMessageFromUser };

export const sendAudioMessageFromUser = (blob: Blob) => ({
  type: AUDIO_MESSAGE_FROM_USER,
  buffer: blob,
});

export const enableSpeechSupport = () => ({
  type: ENABLE_SPEECH_SUPPORT,
});

export const receiveSpeechTranscription = (transcription: string) => ({
  type: RECEIVE_SPEECH_TRANSCRIPTION,
  transcription,
});

export const enableTranscriptExport = (methods: {
  email: boolean;
  pdf: boolean;
}) => ({
  type: ENABLE_TRANSCRIPT_EXPORT,
  methods,
});

export const exportTranscript = (email: string) => ({
  type: ActionTypes.TRANSCRIPT_MAIL_REQUEST,
  payload: { parameters: { mailTo: email } },
});

export const showGeneralConditions = () => ({
  type: SHOW_GENERAL_CONDITIONS,
});

export const downloadTranscriptPdf = () => ({
  type: DOWNLOAD_TRANSCRIPT_PDF,
});

export const enableConversationRating = ({
  rating,
}: EnableConversationRatingType) => ({
  type: ENABLE_CONVERSATION_RATING,
  payload: { rating },
});

export const handleRatingFromUser = (value: number, feedback: string) => ({
  type: ActionTypes.CONVERSATION_RATING_FROM_GUEST,
  payload: { value, feedback },
});

export const requestNLUEvent = (event: string) => coreRequestNLUEvent(event);

export const restorePreviousChatMessages = (messages: Message[]) => ({
  type: RESTORE_PREVIOUS_CHAT_MESSAGES,
  messages,
});

export const handleButtonClick = (button: Button) => {
  switch (button.type) {
    case ButtonTypeEnum.WEB_URL: {
      const urlButton = button as UrlButton;
      return notifyParentToChangePath(urlButton.url);
    }
    case ButtonTypeEnum.POSTBACK: {
      const postbackButton = button as PostbackButton;
      return postbackButton.payload;
    }
    default: {
      throw new Error(`unknown button type ${button.type}`);
    }
  }
};

export const handleLinkClick = (url: string) => notifyParentToChangePath(url);

export const getStarted = () => ({
  type: GET_STARTED,
});

const notifyParentToChangePath = (url: string) => ({
  type: MESSAGE_TO_PARENT_PAGE,
  message: {
    type: 'webclient.change.url',
    url,
  },
});

export const notifyParentOfNewTranslations = (translations: {
  header: {
    restartChat: string | undefined;
    closeChat: string | undefined;
  };
}) => ({
  type: MESSAGE_TO_PARENT_PAGE,
  message: {
    type: 'webclient.change.translations',
    payload: { translations },
  },
});
