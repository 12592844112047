import { Message, QuickReply } from '@botfabrik/engine-domain';

export type TranscriptItemReaction = 'GOOD' | 'BAD' | null | undefined;

export interface ChatMessage extends Message {
  _id: string;
  reaction?: TranscriptItemReaction;
}

export interface StartScreenState {
  useStartScreen: boolean | undefined;
  startScreenStatus?: 'VISIBLE' | 'HIDDEN';
  formValues: Record<string, string | undefined>;
}

export interface ChatState {
  isBotTyping: boolean;
  messages: ChatMessage[];
  quickReplies: QuickReply[];
  drawerContentUrl?: string;
  speechSupport: boolean;
  transcriptExportEnabled: {
    email: boolean;
    pdf: boolean;
  };
  showGeneralConditions: boolean;
  conversationRating: ConversationRatingState;
}

export interface EnableConversationRatingType {
  rating?: ConversationRating;
}

export interface ConversationRatingState {
  enabled: boolean;
  rating?: ConversationRating;
}

export interface ConversationRating {
  value: number;
  feedback: string;
}

export enum ConnectionStateEnum {
  CONNECTING,
  CONNECTED,
  RECONNECTED,
  DISCONNECTED,
}

export interface ConnectionState {
  connectionState: ConnectionStateEnum;
  sessionId: string | undefined;
  userId: string | undefined;
}

export interface Sender {
  avatar: string;
  type: UserType;
}

export enum UserType {
  GUEST = 'GUEST',
  BOT = 'BOT',
  PERSON = 'PERSON',
}
